<template>
  <div>
    <div class="newsList_bg">
      <img :src="newsList_bg" />
    </div>
    <div class="newsList_list">
      <div class="newsList_list_table">
        <ul class="newsList_list_table_ul">
          <li class="moveTop" v-for="item in newsList" :key="item.id" @click="toDetails(item)">
            <div style="width: 350px; height: 180px;overflow: hidden;margin-right: 40px;position: relative;">
              <img src="../../static/newImg/img_loading.png" class="img_loading" v-show="imgSuffix"/>
              <img v-if="item.newsCover" :src="item.newsCover + imgSuffix" style="width: 350px; height: 180px;" />
              <img v-else src="../../static/image/home_news.png" height="180px" width="350px" />
            </div>
            <div>
              <div class="newsList_list_table_ul_li_title">
                {{ item.newsTitle.length > 30 ? item.newsTitle.substring(0, 30) + '...' : item.newsTitle }}
              </div>
              <div class="newsList_list_table_ul_li_content">
                {{ item.newsIntroduction ? (item.newsIntroduction.length > 30 ? item.newsIntroduction.substring(0, 30) + '...' : item.newsIntroduction) : item.title }}
              </div>
              <div class="newsList_list_table_ul_li_time">
                发布时间：{{ item.year }}-{{ item.day }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="newsList_pagination">
      <el-pagination layout="prev, pager, next" :page-size="6" :total="query.total"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "newsList",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: '?x-oss-process=image/resize,m_lfit,w_300',
      newsList_bg: require("../../static/image/newsList_bg.png"),
      newsList: [],
      query: {
        page: 1,
        size: 6,
        total: 0,
      },
    };
  },
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = ''
    }, 4000)
    window.scroll(0, 0);
    this.getList(); // 18 文章推荐
    this.getWXInfo()
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        const obj = {
            title: '中峪农业新闻资讯', // 分享标题
            desc: '最新新闻资讯', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/377f79c3260b40ba8937940cd6292d1f.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    toDetails(item) {
      this.$router.push({
        path: "/newsListDetail",
        query: { id: item.newsId },
      });
    },
    getList() {
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?status=PUBLISHED&current=${this.query.page}&size=6&websiteCategoryId=${18}`,
        )
        .then(async (res) => {
          const content = res.data.data.list;
          content.forEach((item) => {
            const updateTime = new Date(item.publishTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          this.newsList = content;
          this.query.total = res.data.data.total;
        });
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime)
  },
};
</script>

<style scoped lang="scss">
.newsList_bg {
  img {
    width: 100%;
  }
}

.newsList_list {
  margin: 40px auto;
  display: flex;
  width: 1240px;
  padding: 0 20px;

  .newsList_list_table {
    width: 100%;

    .newsList_list_table_ul {
      padding: 0;
      margin: 0;
      overflow: hidden;

      li {
        margin-top: 20px;
        cursor: pointer;
        list-style-type: none;
        line-height: 62px;
        font-size: 14px;
        font-weight: 400;
        color: #1d1b19;
        border-bottom: 1px dashed #e5e5e5;
        cursor: pointer;
        display: flex;
        padding-bottom: 32px;

        .newsList_list_table_ul_li_title {
          font-size: 22px;
          font-family: 宋体;
          font-weight: bold;
          color: #252525;
          line-height: 30px;
        }

        .newsList_list_table_ul_li_content {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #858585;
          line-height: 24px;
          width: 890px;
          overflow: hidden; // 溢出隐藏
          white-space: nowrap; // 强制一行
          text-overflow: ellipsis; // 文字溢出显示省略号
        }

        .newsList_list_table_ul_li_time {
          margin-top: 45px;
          font-size: 12px;
          font-weight: 400;
          color: #abaeb0;
          line-height: 17px;
        }
      }
    }
  }
}

.newsList_pagination {
  text-align: center;
  margin-bottom: 100px;
  margin-top: 50px;
}

::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #354a32;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}

::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}

::v-deep .el-pager li.active+li {
  border: 1px solid #e5e5e5;
}
</style>
